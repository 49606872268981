import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SiteMeta from '../components/SiteMeta';
import MediaHeroWfeHrMetric from '../components/MediaHeroWfeHrMetric';
import WfeHrMetricGlossaryDetail from '../components/WfeHrMetricGlossaryDetail';

const ResourcesPage = ({ data }) => {
  const {
    allContentfulWfeHrMetric,
    contentfulHrMetricGlossary: {
      metaTitle,
      metaDescription: { metaDescription },
      title,
      subtitle,
    },
  } = data;

  const metrics = allContentfulWfeHrMetric.edges.map((n) => n.node);
  const sortedMetrics = metrics.slice().sort((a, b) => a.title.localeCompare(b.title));

  return (
      <Layout>
        <SiteMeta title={metaTitle} description={metaDescription}/>
        <MediaHeroWfeHrMetric {...data.contentfulHrMetricGlossary} />
        <WfeHrMetricGlossaryDetail {...data} />
      </Layout>
  );
};

export default ResourcesPage;

export const query = graphql`
  query {
    contentfulHrMetricGlossary(slug: {eq: "wfe-swi"}) {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      subtitle {
        subtitle
      }
      heroImage {
        file {
           url
        }
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bodyText {
        json
      }
    }
    allContentfulWfeHrMetric {
      edges {
        node {
          slug
          title
          bodyText {
            json
          }
        }
      }
    }
  }
`;
