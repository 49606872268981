import React from 'react';
import RichText from '../RichText';
import Link from '../Link';

const sanitizeValue = (value) => {
  return value.replace(/\s+/g, '-').toLowerCase();
};

const WfeHrMetricGlossaryDetail = (props) => {
  const {
    allContentfulWfeHrMetric,
    contentfulHrMetricGlossary: {
      bodyText,
    },
  } = props;

  const metrics = allContentfulWfeHrMetric.edges.map((n) => n.node);
  const sortedMetrics = metrics.slice().sort((a, b) => a.title.localeCompare(b.title));

  return (
      <div className="hr-metric-glossary-detail">
        <div className="hr-metric-glossary-detail__inner">
          <div className="hr-metric-glossary-detail__body">
            <div className="hr-metric-glossary-detail__content">
              <RichText source={bodyText?.json}/>
              <h2>List of terms and definitions</h2>
              <div className="hr-metric-glossary"> 
                {sortedMetrics.map((wfeHrMetric, index) => {
                  const {
                    slug,
                    title,
                    bodyText,
                  } = wfeHrMetric;
                  const url = `/hr-metrics/${slug}`;

                  return (
                      <div className="metric-item" key={index}>
                        <Link className="metric-item__link" href={url} id={sanitizeValue(title)}>
                          {title}
                        </Link>
                        <RichText source={bodyText?.json}/>
                        <p className="metric-item__link_cta"><Link href={url} id={sanitizeValue(title)}>Access our 2022 HR national benchmark average for {title} here.</Link></p>
                      </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default WfeHrMetricGlossaryDetail;
